import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { IconBtn, PlainBtn } from '@/buttons';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as ChevronIcon } from '@a/icons/right-chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { Fragment, useEffect, useState } from 'react';
import { HomeLogo, scrollToSection } from './Nav';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { NavLinksTypes, navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexSpace, flexStart, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${flexStart};
    ${px};
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.gray5};
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > nav {
        ${flexStart};
        flex-direction: column;
        margin: 0 auto;
        max-width: max-content;
        overflow-y: auto;
        padding-right: 10px;

        > *:last-child {
            margin-bottom: 6px;
        }
    }

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }

    @media (min-height: 768px) {
        padding: 16px 32px;
    }

    @media (min-height: 1024px) {
        padding: 16px 48px;
    }
`;

const Top = styled.div`
    ${flexSpace};
    width: 100%;
    margin-bottom: 32px;

    > button {
        :last-of-type {
            margin-left: auto;
        }
    }

    @media (min-height: 550px) {
        margin-bottom: 48px;
    }

    @media (min-height: 650px) {
        margin-bottom: 64px;
    }

    @media (min-height: 750px) {
        margin-bottom: 80px;
    }
`;

const ExtraLinks = styled.div`
    ${flexSpace};
    width: min(400px, 100%);
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 32px;

    > a:nth-of-type(2) {
        margin-right: auto;
    }

    @media (min-height: 513px) {
        margin-bottom: 4.657vh; // 50px at 730px
        margin-top: 0;
    }
`;

type MobNavProps = {
    text?: string;
    links?: NavLinksTypes;
    dialogsClosed: boolean;
    closeAllDialogs: () => void;
};

export const MobNav = ({ text, links = navLinks, dialogsClosed, closeAllDialogs }: MobNavProps) => {
    const [open, setOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { title, category } = useLocationData();

    const sectionScroll = (link: string) => {
        closeAllDialogs();
        setTimeout(() => scrollToSection(link), 100);
    };

    useEffect(() => {
        setOpen(false);
    }, [dialogsClosed]);

    const currentCategory = category === text || title === text;

    return width < 1280 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                {text ? (
                    <PlainBtn
                        css={navBtnStyles}
                        className={currentCategory ? 'current-nav-category' : ''}
                    >
                        {text} <ChevronIcon />
                    </PlainBtn>
                ) : (
                    <PlainBtn
                        aria-label="open navigation menu"
                        css={css`
                            @media (min-width: 1280px) {
                                display: none;
                            }
                        `}
                    >
                        <MenuIcon />
                    </PlainBtn>
                )}
            </DialogOpen>

            <DialogContent
                contentStyles={css`
                    ${contentStyles};
                    justify-content: ${text ? 'flex-start' : 'space-between'};
                `}
                overlayStyles={overlayStyles}
                title="mobile nav menu"
            >
                <Top>
                    {text ? (
                        <DialogClose>
                            <PlainBtn
                                aria-label="close sub nav menu"
                                css={theme => css`
                                    text-transform: capitalize;
                                    color: ${theme.colors.gray1};
                                    font-weight: 500;

                                    > svg {
                                        transform: scaleX(-1);
                                        margin-right: 10px;
                                    }
                                `}
                            >
                                <ChevronIcon /> {text}
                            </PlainBtn>
                        </DialogClose>
                    ) : (
                        <HomeLogo />
                    )}

                    <PlainBtn onClick={closeAllDialogs} aria-label="close nav menu">
                        <CloseIcon />
                    </PlainBtn>
                </Top>

                <nav>
                    {links.map((data, i) =>
                        data.links ? (
                            <MobNav
                                text={data.text}
                                links={data.links}
                                key={i}
                                dialogsClosed={dialogsClosed}
                                closeAllDialogs={closeAllDialogs}
                            />
                        ) : data.link.startsWith('#') ? (
                            <PlainBtn
                                onClick={() => sectionScroll(data.link)}
                                css={navLinkStyles}
                                key={i}
                            >
                                {data.text}
                            </PlainBtn>
                        ) : data.text.includes('(coming soon)') ? (
                            <p
                                css={theme => css`
                                    ${navLinkStyles}
                                    color: ${theme.colors.gray3};
                                    margin-top: 0;

                                    ::before {
                                        content: none;
                                    }
                                `}
                                key={i}
                            >
                                {data.text}
                            </p>
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                </nav>
                {text ? (
                    ''
                ) : (
                    <ExtraLinks>
                        <IconBtn as={CLink} to={siteInfo.social.facebook}>
                            <FacebookIcon />
                        </IconBtn>
                        <IconBtn as={CLink} to={siteInfo.social.instagram}>
                            <InstagramIcon />
                        </IconBtn>
                        <IconBtn as={CLink} to={siteInfo.phone.link} border>
                            <PhoneIcon />
                        </IconBtn>
                        {/* <IconBtn as={CLink} to="/appointment/" background>
                        <AppointmentIcon />
                    </IconBtn> */}
                    </ExtraLinks>
                )}
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
